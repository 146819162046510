import React from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { getStyleSheet } from '../assets/theme/styles'
import { loadStripe } from '@stripe/stripe-js'
import { CheckoutForm } from '../components/common/Checkout/CheckoutForm/CheckoutForm'
import { Elements } from '@stripe/react-stripe-js'
import { STRIPE_KEYS } from '../utils/constants'

export const Checkout = () => {
    const current = 'Checkout'
    const styles = getStyleSheet()
    const stripePromise = loadStripe(STRIPE_KEYS.PROD.PUBLIC)

    return (
        <View style={localStyles.container}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView style={styles.styleBackground.container} stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current, initial: 0 }} />
                    <View style={[styles.baseStyleContent.container, localStyles.workArea]}>
                        <Elements stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
})

export default Checkout
