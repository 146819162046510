export const STRIPE_KEYS = {
    TEST: {
        PUBLIC: 'pk_test_51M6wrzLr1sVvxmlPCZFFc0hqIi20Y1NA1vr7AhFvYGGGuLdhEWrTLQ3NEiXTCTimbNCiBgN1W7JE3jvmKPmLdZTe000UNEDEd8',
        PRIVATE:
            'sk_test_51M6wrzLr1sVvxmlPxeiNQRWgNjVU4KXM92IgKWIX3lDwNh6ksnqjS98A0EnxmOvDOiD3NpB5n9ArS9IbcjhBUiQK003qaiIL2s',
    },
    PROD: {
        PUBLIC: 'pk_live_51M6wrzLr1sVvxmlP0egpCUPoUdBJzq8UoOnbQdciHWZE2EMiMeGz6Sx13bOKq9bWvMmRdRN5MallZ1QDCgUlnRFn00xd8dn5jD',
        PRIVATE:
            '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',
    },
}
